.get-started-header,
.get-started-subheader {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 30px;
}

.get-started-subheader {
    font-size: 22px;
    font-weight: 900;
}

.get-started-sub-subheader {
    color: var(--CJ-Black-100, #0d0d0d);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 100% */
}
