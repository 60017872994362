.searchcard {
    padding: 40px 32px;
    height: 95%;
    gap: 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.searc-card-title {
    width: 100%;
    /* width: 324px; */
    text-align: center;
    color: #333333;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    word-wrap: break-word;
}

.searcd-card-text {
    width: 100%;
    /* width: 324px; */
    text-align: center;
    color: #0d0d0d;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    word-wrap: break-word;
}
