.client-breadcrumb-item {
    font-weight: bold;
    text-decoration: none;
    color: #243773;
}

.client-breadcrumb-item::after {
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, " >");
}

.data-occupation {
    text-align: left;
    margin: 0px;
    color: #0d0d0d;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
}

.data-name {
    text-align: left;
    margin: 0px;
    color: rgba(0, 84, 160, 1) !important;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
}

.client-card {
    padding: 50px 40px;
    display: inline-flex;
    flex-direction: column;
    border-radius: 24px;
    justify-content: flex-start;
    align-items: center;
    gap: 21px;
    width: 100%;
    height: 100%;
}

/* @media (min-width: 1084px) and (max-width: 1240px) {
    .client-card {
        width: 22rem;
        min-width: 22rem;
        max-width: 22rem;
    }
}

@media (min-width: 992px) and (max-width: 1084px) {
    .client-card {
        width: 19rem;
        min-width: 19rem;
        max-width: 19rem;
    }
}

@media (min-width: 768px) and (max-width: 828px) {
    .client-card {
        width: 22rem;
        min-width: 22rem;
        max-width: 22rem;
    }
}

@media (max-width: 379px) {
    .client-card {
        width: 22rem;
        min-width: 22rem;
        max-width: 22rem;
    }
} */
