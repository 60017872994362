.solution-header-content {
    color: black;
    font-size: 20px;
    font-family: Inter;
    font-weight: 500;
    word-wrap: break-word;
    padding-top: 5%;
    padding-bottom: 2.5%;
}

.toggle-wrapper {
    color: #038c7f;
    font-size: 16px;
    font-family: Inter;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    word-wrap: break-word;
    text-align: right;
    margin-top: 1rem;
}

.toogle-dropdownline-open {
    font-size: 4rem;
    color: #038c7f;
}

.toogle-dropdownline-close {
    font-size: 4rem;
    color: #038c7f;
    rotate: 180deg;
}
.accordion-container {
    width: 100%;
    font-family: Inter;
}
.toggel-all {
    cursor: pointer;
    text-align: right;
}

.accordion-wrapper {
    overflow: hidden;
    margin-bottom: 2rem;
}
.accordion-wrapper .solution-item-container {
    width: 100%;
    text-align: left;
    justify-content: space-between;
    border: none;
    cursor: pointer;

    background: #eeeeee;
    border-bottom: solid 2px;
}
.accordion-wrapper .solution-item-container img {
    height: 4rem;
    margin-bottom: 0.5rem;
}

.solution-item-container.active {
    color: #0d0d0d;
}
.accordion-wrapper .solution-item-container:hover {
    background-image: linear-gradient(
        90deg,
        transparent,
        rgba(0, 0, 0, 0.04),
        transparent
    );
}
.accordion-header {
    color: #0d0d0d;
    font-size: 36px;
    font-family: Inter;
    font-weight: 900;
    word-wrap: break-word;
    margin: 0;
    height: 100% !important;
    align-items: center;
    display: flex;
}
.accordion-wrapper .solution-item-content {
    width: 100%;
    padding-left: 1rem !important;
    color: #0d0d0d !important;
    font-size: 36px !important;
}

.accordion-wrapper .arrow {
    font-size: 4rem;
    transition: 0.5s ease-in-out;
}

.arrow.active {
    rotate: 180deg;
    color: #0d0d0d;
}
.accordion-wrapper .solution-item-content-container {
    transition: height 0.7s ease-in-out;
}
.accordion-wrapper .solution-item-content {
    font-size: 20px;
}

.accordion-wrapper .item-toggle-span {
    color: #0d0d0d;
    font-size: 16px;
    font-family: Inter;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    word-wrap: break-word;
}
.align-right {
    text-align: right;
    margin-top: 1rem;
}

.service-item-title {
    color: #0d0d0d;
    font-size: 24px;
    font-family: Inter;
    font-weight: 700;
    word-wrap: break-word;
    margin-top: 1rem;
}

.service-item-image {
    text-align: right;
}

.solution-service-container {
    width: 100%;
}

.bordor-bottom-1 {
    border-bottom: solid 1px;
}

.secondary-circle-button {
    content: url("../../../public/img/secondary-circle-button.svg");
}

.secondary-circle-button:hover {
    content: url("../../../public/img/secondary-circle-button-hover.svg");
}

.margin-bottom-1r {
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.service-item-lable {
    color: #333333;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    word-wrap: break-word;
    margin-top: 2rem;
}

.service-item-lable2 {
    color: #03685f;
    font-size: 18px;
    font-family: Inter;
    font-weight: 700;
    word-wrap: break-word;
}

.fade-availability {
    color: #a2a2a2;
    opacity: 0.5;
}

@media only screen and (max-width: 992px) {
    .accordion-header {
        color: #0d0d0d;
        font-size: 28px;
        font-family: Inter;
        font-weight: 900;
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 768px) {
    .accordion-header {
        font-size: 26px;
    }

    .service-item-title {
        font-size: 21px;
    }
}

@media only screen and (max-width: 705px) {
    .accordion-header {
        font-size: 21px;
    }

    .service-item-title {
        font-size: 19px;
    }
}

@media only screen and (max-width: 576px) {
    .accordion-header {
        font-size: 19px;
    }

    .accordion-wrapper .arrow {
        font-size: 2rem;
        transition: 0.5s ease-in-out;
    }

    .toggle-open-close {
        padding-top: 3%;
    }

    .secondary-circle-button {
        height: 45px;
        width: 45px;
        display: block;
        margin-top: 16px;
    }
}

@media only screen and (max-width: 400px) {
    .accordion-header {
        font-size: 18px;
    }
    .service-item-lable {
        font-size: 14px;
    }
}

.pt-6 {
    padding-top: 5rem !important;
}
