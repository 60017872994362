@media only screen and (max-width: 576px) {
    .contact-us-cntr {
        padding: 0 30px;
    }
}

@media only screen and (max-width: 768px) {
    .margintop {
        margin-top: 40px;
    }
}
.red-border {
    border-color: red;
}

.error-message,
.span-required {
    color: red;
}

.formlabel-title {
    font-weight: 700;
    font-size: 18px;
    color: #0d0d0d;
}

.btn-submit-form:hover {
    background-color: #fff !important;
    color: #063b67 !important;
    border-color: #063b67 !important;
}

.btn-rest-form:hover {
    background-color: #063b67 !important;
    color: #fff !important;
    border-color: #063b67 !important;
}

.contact-desc {
    font-size: 18px;
    font-weight: 400;
    color: #0d0d0d;
}

.clsinput {
    height: 72px;
    border-radius: 20px;
    background: var(--Neutral-Colors-White, #fff);
}

.clsselect {
    height: 72px;
    border-radius: 20px;
}
