.title-plant-news-post-content{
    padding: 80px 110px 150px 110px;
}


@media only screen and (min-width: 1023px) and (max-width: 1200px) {
    .title-plant-news-post-content{
        padding: 80px 60px 150px 60px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 1023px) {
    .title-plant-news-post-content{
        padding: 80px 30px 150px 30px;
    }
}

@media only screen and (min-width: 374px) and (max-width: 575px) {
    .title-plant-news-post-content{
        padding: 80px 20px 150px 20px;
    }
}

.title-plant-news-post-heading {
    width: 100%;
    font-weight: 700;
    color: #0D0D0D;
    font-size: 20px;
    font-family: Inter;
    line-height: 30px;
    word-wrap: break-word;
}

.title-plant-news-post-desc{
    margin: 0px 0px 15px 0px;
}

.title-plant-news-post-desc,
.title-plant-news-post-county,
.title-plant-news-post-benefits,
.title-plant-news-post-additional-info {
    width: 100%;
    font-weight: 400;
    color: #0D0D0D;
    font-size: 18px;
    font-family: Inter;
    line-height: 30px;
    word-wrap: break-word;
}


.title-plant-news-post-link{
    color: #337ab7;
    text-decoration: none;
}

.title-plant-news-post-link:hover{
    color: #243773;
    cursor: pointer;
    text-decoration: underline;
}