.footer .top-margin {
    margin-top: 5rem;
}

.footer .footer-address {
    color: #0d0d0d;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
}

.footer .footer-service-title {
    color: #243773;
    font-size: 16px;
    font-family: Inter;
    font-weight: 900;
    word-wrap: break-word;
    height: 2.5rem;
}

.footer .footer-service-title a,
.footer .footer-service-title button {
    color: #243773;
    font-size: 16px;
    font-family: Inter;
    font-weight: 900;
    word-wrap: break-word;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
}

.footer .footer-service {
    color: #243773;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    word-wrap: break-word;
    height: 2.5rem;
    width: 120%;
}

@media only screen and (max-width: 576px) {
    .footer .footer-service {
        width: 100%;
    }
}

.footer .footer-service a {
    text-decoration: none;
    color: #243773;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    word-wrap: break-word;
}

.footer .margin-top {
    margin-top: 1rem;
}

.footer .margin-top-2 {
    margin-top: 2rem;
}

.footer .margin-top-5 {
    margin-top: 5rem;
}

.footer .text-align-center {
    text-align: center;
}

.footer .footer-links a {
    text-decoration: none;
    color: #030a8c;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    text-decoration: underline;
    word-wrap: break-word;
}

.footer .social-media-icon {
    margin-bottom: 3rem;
}

.footer .social-media-icon a {
    margin-right: 1rem;
}

.footer .bottom-content {
    width: 90%;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

@media only screen and (min-width: 577px) and (max-width: 991px) {
    .text-align-center-m {
        text-align: center;
    }
}

@media only screen and (max-width: 576px) {
    .text-align-center-m {
        text-align: center;
    }
}

@media only screen and (max-width: 575px) {
    .margin-left-m {
        margin-left: 30%;
    }
}

@media only screen and (min-width: 527px) and (max-width: 767px) {
    .footer-link-cntr {
        margin-left: 5rem !important;
    }
}

@media only screen and (min-width: 469px) and (max-width: 526px) {
    .footer-link-cntr {
        margin-left: 4rem !important;
    }
}
