.app-privacy-notice {
    display: block;
    position: fixed;
    border-radius: 4px;
    padding: 24px 30px 24px 30px;
    text-align: start;
    z-index: 1;
    color: #fff;
    background: #cceeff;
    border: solid 1px #939598;
    bottom: 0px;
    transition: 0.5s;
    transform: translateY(0%);
}

/* @media only screen and (min-width: 1201px) and (max-width: 1445px) {
    .app-privacy-notice {
        top: 78vh;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .app-privacy-notice {
        top: 74vh;
    }
}

@media only screen and (min-width: 721px) and (max-width: 1024px) {
    .app-privacy-notice {
        top: 70vh;
    }
}

@media only screen and (min-width: 430px) and (max-width: 720px) {
    .app-privacy-notice {
        top: 65vh;
    }
} */

.privacy-notice-title {
    width: 100%;
    font-weight: 700;
    color: #0d0d0d;
    font-size: 16px;
    font-family: Inter;
    word-wrap: break-word;
}

.privacy-notice-content {
    display: inline-flex;
    flex-direction: row;
}

.privacy-notice-desc {
    width: 101%;
    font-weight: 400;
    color: #0d0d0d;
    font-size: 14px;
    font-family: Inter;
    line-height: 20px;
    word-wrap: break-word;
    margin-top: 0px;
}

.privacy-policy-link {
    color: #337ab7;
    text-decoration: none;
}

.privacy-policy-link:hover {
    color: #243773;
    cursor: pointer;
    text-decoration: underline;
}

.privacy-notice-right{
    width: 10%;
    display: inline-flex;
    justify-content: end;
    align-items: flex-start;
    flex: auto;
}

.privacy-notice-left {
    flex: auto;
}

@media only screen and (min-width: 576px) and (max-width: 679px) {
    .privacy-notice-right{
        width: 20%;
    }
}

@media only screen and (min-width: 374px) and (max-width: 576px) {
    .privacy-notice-right{
        width: 30%;
    }
}
