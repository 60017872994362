.testimonials-content {
    padding: 80px 110px 150px 110px;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.testimonials-content.row > * {
    padding-right: 20px;
    padding-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .testimonials-content {
        padding: 80px 60px 150px 60px;
    }
}

@media only screen and (max-width: 768px) {
    .testimonials-content {
        padding: 80px 30px 150px 30px;
    }
}
