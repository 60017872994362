.faqs-content {
    padding: 80px 110px 150px 110px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .faqs-content {
        padding: 80px 60px 150px 60px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 1024px) {
    .faqs-content {
        padding: 80px 30px 150px 30px;
    }
}

@media only screen and (min-width: 374px) and (max-width: 576px) {
    .faqs-content {
        padding: 80px 20px 150px 20px;
    }
}

.left-badge {
    width: 100%;
    height: auto;
    padding: 20px;
    background: #243773;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
}

.cj110-years {
    width: 100%;
    height: 275px;
}

.left-badge {
    padding-bottom: 60px;
}

.faqs-expert-title {
    width: 100%;
    color: #181818;
    font-size: 24px;
    font-weight: 900;
    text-transform: capitalize;
    line-height: 27.84px;
    word-wrap: break-word;
    margin-top: 60px;
}

/* .faqs-content.row > * {
    padding-right: 20px;
    padding-left: 20px;
} */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .faqs-content {
        padding: 80px 60px 150px 60px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
    .faqs-content {
        padding: 80px 30px 150px 30px;
    }
}

@media only screen and (min-width: 374px) and (max-width: 575px) {
    .faqs-content {
        padding: 80px 20px 150px 20px;
    }
}

.faqs-heading {
    width: 100%;
    color: #0d0d0d;
    font-size: 36px;
    font-family: Inter;
    font-weight: 900;
    word-wrap: break-word;
    margin-bottom: 40px;
}

.faqs-heading-desc1,
.faqs-heading-desc2 {
    width: 100%;
    color: #0d0d0d;
    font-size: 18px;
    font-family: Inter;
    font-weight: 400;
    line-height: 30px;
    word-wrap: break-word;
    margin-bottom: 40px;
}

.faqs-heading-desc1 {
    padding-right: 5px;
}

.faqs-heading-desc2 {
    padding-right: 25px;
}

.faqs-impinfo {
    margin-left: -15px;
    margin-bottom: 40px;
}

.faqs-impinfo-desc a {
    width: 100%;
    color: #243773;
    font-size: 18px;
    font-family: Inter;
    font-weight: 500;
    line-height: 30px;
    word-wrap: break-word;
    text-decoration: none;
    cursor: unset;
    margin-bottom: 40px;
    cursor: pointer;
}

.faqs-judgment-search,
.faqs-patriot-name-search,
.faqs-flood-search,
.faqs-tideland-search,
.faqs-tax-reform {
    margin-bottom: 40px;
}

.faqs-judgment-search-title,
.faqs-patriot-name-search-title,
.faqs-flood-search-title,
.faqs-tideland-search-title,
.faqs-tax-reform-title {
    width: 100%;
    color: #0d0d0d;
    font-size: 20px;
    font-family: Inter;
    font-weight: 700;
    line-height: 30px;
    word-wrap: break-word;
}

.faqs-judgment-search-desc,
.faqs-patriot-name-search-desc,
.faqs-flood-search-desc,
.faqs-tideland-search-desc,
.faqs-tax-reform-desc {
    color: #0d0d0d;
    width: 100%;
    font-size: 18px;
    font-family: Inter;
    font-weight: 400;
    line-height: 30px;
    word-wrap: break-word;
    margin-bottom: 5px;
}

.faqs-judgment-search-link,
.faqs-patriot-name-search-link,
.faqs-flood-search-link,
.faqs-tideland-search-link,
.faqs-tax-reform-link {
    color: #243773;
    font-size: 18px;
    font-family: Inter;
    font-weight: 400;
    text-decoration: underline;
    line-height: 30px;
    word-wrap: break-word;
}

.faqs-arrow {
    text-decoration: none;
    cursor: pointer;
}

.faqs-links {
    margin-bottom: 15px;
}

.faqs-left-content {
    padding-bottom: 190px;
}
