.thumbnail {
    border-radius: 15px;
    margin: 10px 0 20px 0;
    width: 100%;
}
.expert-heading-title {
    font-weight: 600;
    font-size: 24px;
}
.expert-desc {
    font-size: 18px;
}

/* .expert-desc, .expert-heading{
    width: 400px;
}

@media only screen and (min-width: 577px) and (max-width: 992px){
    .expert-desc, .expert-heading{
        width: 708px;
    }
}

@media only screen and (min-width: 430px) and (max-width: 576px){
    .expert-desc, .expert-heading{
        width: 516px;
    }
} */

.thumbnail-footer {
    overflow: auto;
    margin: 25px 0;
}
.thumbnail-footer img {
    width: 62px;
    border-radius: 100%;
}
.thumbnail-footer span {
    margin-left: 10px;
    font-size: 20px;
}
.thumbnail-footer .arrow {
    float: right;
    cursor: pointer;
}

.btn-readmore:hover {
    background-color: #063b67 !important;
    color: #fff !important;
    border-color: #063b67 !important;
}

.article-card {
    padding: 50px 40px;
    border-radius: 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (min-width: 576px) and (max-width: 728px) {
    .btn-readmore {
        width: 34%;
        padding: 13px;
    }
}

@media only screen and (min-width: 1200px) {
    .expert-card {
        padding: 20px;
    }
}

@media only screen and (max-width: 1200px) {
    .expert-card {
        padding: 20px 50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .expert-card {
        padding: 20px 100px;
    }
}

@media only screen and (min-width: 818px) and (max-width: 992px) {
    .expert-card {
        padding: 20px 140px;
    }
}

@media only screen and (max-width: 576px) {
    .expert-card {
        padding: 20px 20px;
    }
}
