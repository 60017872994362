.innerHeader {
    background-color: #243773;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 0px 1%;
    height: 400px;
}

.innerHeader h1 {
    font-size: 64px;
    font-weight: 900;
}

.innerHeader h1,
.innerHeader p {
    width: 100%;
    text-align: center;
    color: #fff;
    word-wrap: break-word;
}

.innerHeader p {
    font-size: 24px;
    font-weight: 400;
    padding: 0px 1%;
}

@media only screen and (min-width: 769px) and (max-width: 1425px) {
    .innerHeader h1 {
        font-size: 52px;
        font-weight: 900;
    }
}

@media only screen and (min-width: 561px) and (max-width: 768px) {
    .innerHeader h1 {
        font-size: 42px;
        font-weight: 900;
    }

    .innerHeader p {
        font-size: 20px;
    }

}

@media only screen and (min-width: 430px) and (max-width: 560px) {
    .innerHeader h1 {
        font-size: 38px;
        font-weight: 900;
    }

    .innerHeader p {
        font-size: 18px;
    }

}

@media only screen and (min-width: 374px) and (max-width: 430px) {
    .innerHeader h1 {
        font-size: 34px;
        font-weight: 900;
    }

    .innerHeader p {
        font-size: 16px;
    }
}