.newsinsight-content {
    padding: 80px 110px 150px 110px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.newsinsight-title {
    width: 100%;
    color: #0d0d0d;
    font-size: 36px;
    font-family: Inter;
    font-weight: 900;
    word-wrap: break-word;
    padding-right: 2%;
}

.newsinsight-badge {
    width: 62px;
    height: 62px;
    border-radius: 100%;
}

.newsinsight-name {
    width: 150px;
    color: #0d0d0d;
    font-size: 20px;
    font-family: Inter;
    font-weight: 600;
    line-height: 29.5px;
    word-wrap: break-word;
    padding-left: 1%;
}

.newsinsight-date {
    width: 140px;
    color: #555960;
    font-size: 18px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
}

.newsinsight-socialmediaicons-share {
    width: 55px;
    height: 21px;
    color: #667085;
    font-size: 14px;
    font-family: Inter;
    font-weight: 700;
    text-transform: uppercase;
    word-wrap: break-word;
    margin-right: 16px;
    padding-top: 6px;
    text-align: end;
    cursor: pointer;
}

.newsinsight-socialmediaicons {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
}

.newsinsight-expert,
.newsinsight-socialicons {
    display: flex;
    flex-direction: row;
}

.newsinsight-expert {
    padding: 20px 0px;
}

.newsinsight-socialicons {
    width: 100%;
    margin: 25px 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    justify-content: end;
    align-items: center;
}

.newsinsight-desc1 {
    width: 100%;
    color: #0d0d0d;
    font-size: 18px;
    font-family: Inter;
    font-style: italic;
    font-weight: 300;
    line-height: 30px;
    word-wrap: break-word;
}

.newsinsight-frame {
    width: 100%;
    height: auto;
}

.newsinsight-desc2,
.newsinsight-desc3 {
    color: #0d0d0d;
    font-size: 18px;
    font-family: Inter;
    font-weight: 400;
    line-height: 30px;
    word-wrap: break-word;
}

.newsinsight-impinfo {
    padding-left: 1rem;
}

.newsinsight-highlight {
    font-weight: 900;
}

.left-badge {
    width: 100%;
    height: auto;
    padding: 20px;
    background: #243773;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
}

.cj110-years {
    width: 100%;
    height: 275px;
}

.left-badge {
    padding-bottom: 60px;
}

.newsinsight-expert-title {
    width: 100%;
    color: #181818;
    font-size: 24px;
    font-weight: 900;
    text-transform: capitalize;
    line-height: 27.84px;
    word-wrap: break-word;
    margin-top: 60px;
}

/* .newsinsight-content.row > * {
    padding-right: 20px;
    padding-left: 20px;
} */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .newsinsight-content {
        padding: 80px 60px 150px 60px;
    }
}

@media only screen and (min-width: 430px) and (max-width: 768px) {
    .newsinsight-content {
        padding: 80px 30px 150px 30px;
    }
}

@media only screen and (min-width: 374px) and (max-width: 430px) {
    .newsinsight-content {
        padding: 80px 20px 150px 20px;
    }
}

.newsinsight-content-left {
    padding: 0px 20px 190px 20px;
}
