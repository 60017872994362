.about-content {
    padding: 80px 110px 150px 110px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    display: inline-flex;
}

.left-badge {
    width: 100%;
    height: auto;
    padding: 20px;
    background: #243773;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
}

.cj110-years {
    width: 100%;
    height: 275px;
}

.about-frame {
    padding: 40px 0px;
    width: 95%;
    height: auto;
}

.about-content-heading {
    width: 100%;
    color: #0d0d0d;
    font-size: 24px;
    font-family: Inter;
    font-weight: 900;
    line-height: 30px;
    word-wrap: break-word;
}

.about-content-desc {
    width: 99%;
    color: #0d0d0d;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    word-wrap: break-word;
}

.left-badge,
.about-expert-info {
    padding-bottom: 60px;
}

.about-expert-title {
    width: 100%;
    color: #181818;
    font-size: 24px;
    font-weight: 900;
    text-transform: capitalize;
    line-height: 27.84px;
    word-wrap: break-word;
    margin-top: 60px;
}

/* .about-content.row > * {
    padding-right: 40px;
    padding-left: 0px;
} */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .about-content {
        padding: 80px 60px 150px 60px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
    .about-content {
        padding: 80px 30px 150px 30px;
    }
}

@media only screen and (min-width: 374px) and (max-width: 576px) {
    .about-content {
        padding: 80px 20px 150px 20px;
    }
}