.executive-profiles {
    padding: 40px 0px 150px 0px;
}

.executive-profile-info {
    padding: 0px 0px 40px 0px;
}

.profile-dp {
    width: 100%;
    margin: 0px 50px 40px 0px;
    border-radius: 20px
}

.profile-name {
    color: #0D0D0D;
    font-size: 36px;
    font-family: Inter;
    font-weight: 900;
    line-height: 46px;
    word-wrap: break-word;
    margin-left: 50px;
}

.profile-occupation {
    color: #333333;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 18px;
    letter-spacing: 0.80px;
    word-wrap: break-word;
    margin-left: 50px;
}

.profile-smiocns{
    margin-left: 50px;
}

.profile-socialmediaicons {
    width: 36px;
    background: white;
    border-radius: 8px;
    border: 1px #243773;
    margin-right: 16px;
}

.profile-desc1, .profile-desc2, .profile-desc4, .profile-desc5 {
    width: 100%;
    color: #0D0D0D;
    font-size: 18px;
    font-family: Inter;
    font-weight: 500;
    line-height: 30px;
    word-wrap: break-word;
}

.profile-desc3{
    width: 100%;
    color: #0D0D0D;
    font-size: 18px;
    font-family: Inter;
    font-weight: 500;
    word-wrap: break-word;
    margin-bottom: 0px;
}


@media only screen  and (min-width: 576px) and (max-width: 767px) {
    .profile-dp{
         width:516px;
         margin-right: 0px;
    }
    .profile-name, .profile-occupation, .profile-socialmediaicons, .profile-smiocns
     {
        margin-left: 0px;
    }
}


@media only screen  and (min-width: 374px) and (max-width: 576px) {
    .profile-dp{
         width:100%;
         margin-right: 0px;
    }
    .profile-name, .profile-occupation, .profile-socialmediaicons, .profile-smiocns
     {
        margin-left: 0px;
    }
}