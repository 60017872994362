@font-face {
    font-family: "Inter";
    src: url("../public/font/Inter/Inter-VariableFont_opsz,wght.ttf");
}

@font-face {
    font-family: "Inter";
    src: url("../public/font/Inter/Inter-Italic-VariableFont_opsz,wght.ttf");
    font-style: italic;
}

* {
    font-family: "Inter", sans-serif;
}

html,
body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    overflow-x: hidden;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* Common Colors */

:root {
    --cj-blue-sky: #243773;
    --cj-marine: #038c7f;
}

.bg-cj-blue-sky {
    background-color: var(--cj-blue-sky);
}

.cj-blue-sky {
    color: var(--cj-blue-sky);
}

/* Text and Headings */

/* Navbar */
.cj-navbar {
    min-height: 103px;
    margin-top: 15px;
    margin-bottom: 15px;
}

#navbar-toggle {
    background-color: var(--cj-blue-sky);
    margin-left: auto !important;
    border-radius: 0;
    border: 0;
    padding: 20px;
}

#navbar-toggle:focus,
#navbar-toggle:focus-visible {
    box-shadow: none;
}

span.navbar-toggler-icon {
    background-image: url(../public/img/right-arrow.svg);
    border: 0;
}

span.navbar-exit-icon {
    background-image: url(../public/img/nav-close.svg);
    width: 30px;
    height: 30px;
}

@media only screen and (min-width: 980px) and (max-width: 1260px) {
    #nav-cj-logo {
        width: 206px;
    }

    .cj-navbar {
        min-height: 103px;
    }
}

@media only screen and (min-width: 870px) and (max-width: 980px) {
    #nav-cj-logo {
        width: 175px;
    }

    .cj-navbar {
        min-height: 88px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 870px) {
    #nav-cj-logo {
        width: 160px;
    }

    .cj-navbar {
        min-height: 80px;
    }
}

@media only screen and (max-width: 510px) {
    #nav-cj-logo {
        width: 206px;
    }

    .cj-navbar {
        min-height: 103px;
    }
}

@media only screen and (max-width: 406px) {
    #nav-cj-logo {
        width: 175px;
    }

    .cj-navbar {
        min-height: 88px;
    }
}

@media only screen and (max-width: 379px) {
    #nav-cj-logo {
        width: 160px;
    }

    .cj-navbar {
        min-height: 80px;
    }
}

div.header-nav-link,
a.header-nav-link,
a.dropdown-toggle {
    font-weight: 900;
    font-size: 18px;
    color: #000;
}

button.dropdown-toggle::after {
    content: url(../public/img/dropdown-arrow.svg);
    border-top: 0;
    vertical-align: 0.1em;
}

button.dropdown-toggle-inverted::after {
    transform: rotate(180deg);
    position: relative;
    top: 4px;
}

@media only screen and (max-width: 1260px) {
    div.header-nav-link,
    a.header-nav-link,
    a.dropdown-toggle {
        font-size: 16px;
    }

    /* button.dropdown-toggle::after {
    width: 23px;
  } */
}

@media only screen and (max-width: 980px) {
    div.header-nav-link,
    a.header-nav-link,
    a.dropdown-toggle {
        font-size: 14px;
    }

    /* button.dropdown-toggle::after {
    width: 21px;
  } */
}

@media only screen and (max-width: 886px) {
    div.header-nav-link,
    a.header-nav-link,
    a.dropdown-toggle {
        font-size: 12px;
    }

    button.dropdown-toggle::after {
        transform: scale(0.75);
    }

    button.dropdown-toggle-inverted::after {
        transform: scale(0.75) rotate(180deg);
        position: initial;
        top: 0;
    }
}

div.dropdown-menu.show {
    display: none;
}

.desktop-dropdown {
    position: absolute;
    background: rgba(255, 255, 255, 0.94);
    z-index: 1;
}

.blue-nav-card {
    background-color: var(--cj-blue-sky);
    border-radius: 20px;
    padding: 20px;
    color: #fff;
}

.nav-card-header {
    font-size: 24px;
    font-weight: 800;
    line-height: 26px;
}

@media only screen and (max-width: 1135px) {
    .nav-card-header {
        font-size: 20px;
        font-weight: 800;
        line-height: 22px;
    }
}

.nav-card-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 26px;
}

@media only screen and (max-width: 1135px) {
    .nav-card-text {
        font-size: 12px;
        font-weight: 300;
        line-height: 24px;
    }
}

.nav-card-category {
    font-size: 18px !important;
    font-weight: 900 !important;
    color: var(--cj-blue-sky) !important;
    margin-bottom: 0;
    text-decoration: none !important;
}

.nav-card-sub-header {
    font-size: 18px;
    font-weight: 900;
    color: var(--cj-blue-sky);
    margin-bottom: 0;
    text-decoration: none;
}

@media only screen and (max-width: 1135px) {
    .nav-card-sub-header {
        font-size: 14px;
        font-weight: 900;
        color: var(--cj-blue-sky);
        text-decoration: none;
    }
}

a.nav-card-link {
    font-weight: 500;
    color: var(--cj-blue-sky);
    font-size: 16px;
}

@media only screen and (max-width: 1135px) {
    a.nav-card-link {
        font-weight: 500;
        color: var(--cj-blue-sky);
        font-size: 14px;
    }
}

.icon-btn:hover .btn-arrow-right,
.icon-btn:active .btn-arrow-right {
    background-image: url(../public/img/right-arrow.svg);
}

.icon-btn:hover .btn-arrow-right-white,
.icon-btn:active .btn-arrow-right-white {
    background-image: url(../public/img/right-arrow-blue.svg);
}

@media only screen and (max-width: 1010px) {
    #solution-card {
        display: none;
    }
}

.mobile-dropdown-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px 40px;
    font-weight: 900;
    size: 18px;
    color: var(--cj-blue-sky);
    display: flex;
    text-decoration: none;
}

.mobile-dropdown-row:hover {
    cursor: pointer;
}

/* Buttons */

.sign-on-span {
    color: red !important;
}

button.btn {
    border-radius: 30px;
    gap: 8px;
    padding: 18px 24px 18px 24px;
    font-size: 16px;
    font-weight: 900;
    line-height: 18px;
}

@media only screen and (max-width: 1260px) {
    button.btn-nav {
        padding: 14px 18px;
        font-size: 12px;
        line-height: 14px;
    }
}

@media only screen and (max-width: 980px) {
    button.btn-nav {
        padding: 10px 14px;
        font-size: 10px;
        line-height: 12px;
    }
}

@media only screen and (max-width: 886px) {
    button.btn-nav {
        padding: 6px 10px;
        font-size: 8px;
        line-height: 10px;
    }
}

button.btn-cj-primary,
button.btn-cj-secondary:hover {
    background-color: var(--cj-blue-sky) !important;
    border: 1px solid #fff !important;
    color: #fff !important;
}

button.btn-cj-secondary,
button.btn-cj-primary:hover,
button.btn-cj-transparent:hover {
    background-color: #fff !important;
    color: var(--cj-blue-sky) !important;
    border: 1px solid var(--cj-blue-sky) !important;
}

button.btn-cj-transparent {
    background-color: transparent !important;
    color: #fff !important;
    border: 1px solid #fff !important;
}

.btn-arrow-right,
.btn-arrow-left,
.btn-arrow-right-white {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-image: url(../public/img/right-arrow-blue.svg);
    background-repeat: no-repeat;
    background-position: left;
    background-size: 100%;
    color: var(--cj-blue-sky);
    margin-bottom: 3px;
}

.btn-arrow-right-white {
    background-image: url(../public/img/right-arrow.svg);
}

.btn-arrow-left {
    transform: rotate(180deg);
}

@media only screen and (max-width: 1135px) {
    .learn-more-button {
        font-size: 12px !important;
        padding: 13.5px 18px !important;
    }

    .learn-more-arrow {
        height: 14px;
        width: 14px;
    }
}

.privacy-policy-container {
    padding-top: 176px;
    padding-bottom: 100px;
}

.privacy-policy-text-link {
    font-weight: 900;
    font-size: 20px;
    color: #000 !important;
    text-decoration: none;

    display: block !important;
}

span.privacy-policy-inner-text {
    font-weight: normal;
    text-decoration: underline;
    padding-left: 5px;
}

.privacy-policy-text {
    color: #000;

    font-feature-settings: "liga" off, "clig" off;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
}

/* Home Page */

/* Hero */
.home-hero-image {
    background-image: url(../public/img/trenton-skyline.jpg);
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (max-width: 767px) {
    .home-hero-image {
        margin-bottom: 300px;
    }
}

.home-hero-overlay {
    background-color: rgba(36, 55, 115, 0.95);
    background-size: 100% 100%;
    color: white;
    backdrop-filter: blur(2.5px);
}

#hero-rectangle {
    height: 11px;
    width: 11px;
    background-color: var(--cj-marine);
    display: inline-block;
    margin-right: 10px;
}

.home-hero-sub {
    font-size: 20px;
    font-weight: 500;
    display: inline;
    margin: 0;
}

.home-hero-hdg {
    font-size: 32px;
    font-weight: 800;
}

.home-hero-txt {
    font-size: 18px;
    font-weight: 400;
}

#mobile-hero {
    height: 630px;
    background-position: right;
}

.mobile-hero-content {
    position: relative;
    top: 283px;
}

@media only screen and (max-width: 980px) {
    .home-hero-sub {
        font-size: 20px;
    }

    .home-hero-hdg {
        font-size: 32px;
    }

    .home-hero-txt {
        font-size: 18px;
        font-weight: 400;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1013px) {
    #login-button {
        display: block;
        margin-left: 0 !important;
        margin-top: 20px !important;
    }

    div.home-hero-content {
        padding-left: 50px !important;
        padding-right: 40px !important;
    }

    .home-hero-sub {
        font-size: 18px;
    }

    .home-hero-hdg {
        font-size: 28px;
    }

    .home-hero-txt {
        font-size: 16px;
        font-weight: 400;
    }

    .home-hero-image {
        height: 700px !important;
    }
}

@media only screen and (min-width: 1440px) {
    div.home-hero-content {
        padding-left: 75px !important;
        padding-right: 45px !important;
    }

    .home-hero-button {
        padding: 24px 36px !important;
        font-size: 18px !important;
        border-radius: 40px !important;
    }
}

@media only screen and (max-width: 560px) {
    .home-hero-sub {
        font-size: 18px;
    }

    .home-hero-hdg {
        font-size: 28px;
    }

    .home-hero-txt {
        font-size: 16px;
        font-weight: 400;
    }

    #mobile-button-row {
        display: block !important;
    }

    #mobile-login-button {
        display: block;
        margin-left: 0 !important;
        margin-top: 20px !important;
    }

    #mobile-hero {
        height: 700px;
    }
}

/* Common Classes */
div.xl-max-size {
    max-width: 1600px;
    margin: 0 auto;
}

/* Experts Section */

.experts-container {
    background: var(--CJ-Off-White, #f3f3f3);
}

.expert-title {
    font-weight: 900;
    font-size: 36px;
}

.experts-horizontal-view {
    padding: 200px 0;
}

@media only screen and (min-width: 1200px) {
    .expert-heading {
        display: flex !important;
    }
}

@media only screen and (min-width: 618px) and (max-width: 1200px) {
    .expert-heading {
        display: none !important;
    }

    .expert-heading-smlg {
        text-align: center;
    }

    .expert-heading-smlg,
    .btn-viewall-smlg {
        display: block !important;
    }
}

@media only screen and (min-width: 533px) and (max-width: 618px) {
    .expert-heading {
        display: none !important;
    }

    .expert-heading-smlg {
        text-align: center;
    }

    .expert-heading-smlg,
    .btn-viewall-smlg {
        display: block !important;
    }
}

@media only screen and (max-width: 532px) {
    .expert-heading {
        display: none !important;
    }

    .expert-heading-smlg {
        text-align: center;
    }

    .expert-heading-smlg,
    .btn-viewall-smlg {
        display: block !important;
    }
}

@media only screen and (min-width: 430px) and (max-width: 531px) {
    .expert-heading {
        display: none !important;
    }

    .expert-heading-smlg {
        text-align: center;
    }

    .expert-heading-smlg,
    .btn-viewall-smlg {
        display: block !important;
    }
}

/* Contact Us Page */
/* Contact Us Page Icons */

.row-icon-phone-left {
    margin-top: 6% !important;
    margin-bottom: 5%;
}

.icon-phone-left {
    display: inline-block;
    width: 20px !important;
    height: 22px;
    vertical-align: middle;
    background-image: url(../public/img/phone-logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    filter: brightness(0) saturate(100%) invert(21%) sepia(10%) saturate(5993%)
        hue-rotate(197deg) brightness(93%) contrast(94%);
    margin-bottom: 3px;
    margin-right: 2%;
}

.icon-location-left {
    display: inline-block;
    width: 20px !important;
    height: 21px;
    vertical-align: middle;
    background-image: url(../public/img/mark-logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    filter: brightness(0) saturate(100%) invert(21%) sepia(10%) saturate(5993%)
        hue-rotate(197deg) brightness(93%) contrast(94%);
    margin-bottom: 3px;
    margin-right: 2%;
}

.btn-view-all {
    background-color: rgba(36, 55, 115, 0.95);
    color: white;
}

.btn-view-all:hover {
    background-color: white;
    color: rgba(36, 55, 115, 0.95);
}

.btn-cj-transparent.btn-get-started:hover {
    background-color: white !important;
    color: rgba(36, 55, 115, 0.95) !important;
    border-color: rgba(36, 55, 115, 0.95) !important;
}

.btn-cj-transparent.btn-get-started {
    background-color: rgba(36, 55, 115, 0.95) !important;
    color: white !important;
}

.search-card,
.clientsHeader {
    margin-bottom: 30px;
}

.client-row-container {
    background-color: #fbf9ed;
}

@media (min-width: 1200px) {
    .clientRow {
        padding: 200px 110px 250px 110px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .clientRow {
        padding: 150px 62px 200px 62px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .clientRow {
        padding: 150px 62px 200px 62px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
    .clientRow {
        padding: 150px 62px 200px 62px;
    }
    .clientsHeader-title {
        font-size: 35px !important;
    }
}

@media only screen and (max-width: 576px) {
    .clientRow {
        padding: 150px 30px 200px 30px;
    }
    .clientsHeader-title {
        font-size: 32px !important;
    }
}

.testmonials {
    /* width: 100%;  */
    text-align: center;
    color: #038c7f !important;
    font-size: 18px;
    font-family: Inter;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.08px;
    word-wrap: break-word;
    margin: 5px;
}

.clientsHeader-title {
    /* width: 100%;  */
    color: #0d0d0d;
    font-size: 36px;
    font-family: Inter;
    font-weight: 900;
    text-transform: capitalize;
    word-wrap: break-word;
    text-align: center;
    margin: 5px;
    word-wrap: break-word;
}

.holder-desc {
    /* width: 100%; */
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #0d0d0d;
    word-wrap: break-word;
    margin-top: 0px;
}

.searchCard-header,
.clientsHeader {
    display: inline-grid;
    justify-content: center;
}
@media only screen and (min-width: 1201px) {
    .testmonials,
    .clientsHeader-title,
    .holder-desc {
        width: 1016px;
    }

    .testmonials,
    .clientsHeader-title {
        margin-bottom: 10px;
    }

    .holder-desc {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .testmonials,
    .clientsHeader-title,
    .holder-desc {
        width: 904px;
    }

    .testmonials,
    .clientsHeader-title {
        margin-bottom: 10px;
    }

    .holder-desc {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .testmonials,
    .clientsHeader-title,
    .holder-desc {
        width: 708px;
    }

    .testmonials,
    .clientsHeader-title {
        margin-bottom: 10px;
    }

    .holder-desc {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .testmonials,
    .clientsHeader-title,
    .holder-desc {
        width: 516px;
    }

    .testmonials,
    .clientsHeader-title {
        margin-bottom: 10px;
    }

    .holder-desc {
        margin-bottom: 30px;
    }
}

.housead-badge {
    margin-top: 0px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
    .housead-badge {
        margin-top: -19px;
        margin-bottom: 10px;
    }
}

.house-ad {
    padding: 9rem 3rem;
    align-items: center;
    background-blend-mode: multiply, normal;
    background-image: url(../public/img/house-ad.png);
    background-repeat: no-repeat;
    background-position: center;
    display: inline-flex;
    flex-direction: row;
    gap: 0px;
    justify-content: flex-start;
    align-items: center;
    background-size: cover;
}

@media only screen and (min-width: 1200px) {
    .house-ad {
        align-items: center;
        width: 100%;
        background-blend-mode: multiply, normal;
        background-image: url(../public/img/house-ad.png);
        background-repeat: no-repeat;
        background-position: center;
    }
}

.house-ad h1 {
    font-size: 32px;
    font-weight: 900;
    color: #fff;
    width: 100%;
    word-wrap: break-word;
}

.house-ad p {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    width: 100%;
    word-wrap: break-word;
}

.house-ad-img {
    width: 100%;
    height: 100%;
    display: inline-block;
    max-width: 450px;
}

@media only screen and (min-width: 889px) {
    .house-ad-img {
        width: 80%;
        height: 80%;
    }

    .house-img-cntr {
        text-align: center;
    }
}

@media only screen and (min-width: 587px) and (max-width: 768px) {
    .house-ad-img {
        width: 65%;
        height: 65%;
    }

    .house-img-cntr {
        text-align: center;
    }
}

@media only screen and (min-width: 441px) and (max-width: 586px) {
    .house-ad-img {
        width: 75%;
        height: 75%;
    }

    .house-img-cntr {
        text-align: center;
    }
}

@media only screen and (max-width: 440px) {
    .house-ad-img {
        width: 85%;
        height: 85%;
    }

    .house-img-cntr {
        text-align: center;
    }
}

#learn-more-button {
    margin: 0px !important;
}

/* common contact form */

.clientsHeader-title {
    /* width: 100%;  */
    color: #0d0d0d;
    font-size: 36px;
    font-family: Inter;
    font-weight: 900;
    text-transform: capitalize;
    word-wrap: break-word;
    text-align: center;
    margin: 5px;
    word-wrap: break-word;
}

.holder-desc {
    /* width: 100%; */
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #0d0d0d;
    word-wrap: break-word;
    margin-top: 0px;
}

.searchCard-header,
.clientsHeader {
    display: inline-grid;
    justify-content: center;
}

/* common contact form */

.solutions-details-page-img {
    width: 100%;
    border-radius: 15px;
    margin-bottom: 1rem;
}

.solutions-details-page-title {
    color: #0d0d0d;
    font-size: 36px;
    font-family: Inter;
    font-weight: 900;
    word-wrap: break-word;
}

.solutions-details-page-details {
    color: #333333;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    word-wrap: break-word;
}

.solutions-details-page-info {
    color: #03685f;
    font-size: 18px;
    font-family: Inter;
    font-weight: 700;
    word-wrap: break-word;
}

.solutions-details-page {
    margin-top: 5rem !important;
    margin-bottom: 4rem !important;
}

.solutions-details-page-p {
    color: #0d0d0d;
    font-size: 18px;
    font-family: Inter;
    font-weight: 600;
    line-height: 30px;
    word-wrap: break-word;
}

.solutions-details-page-info {
    color: #03685f;
    font-size: 18px;
    font-family: Inter;
    font-weight: 700;
    word-wrap: break-word;
}

.solutions-details-toogle-dropdownline-open {
    font-size: 3rem;
    color: #8d8ba7;
    margin-left: -1rem;
}

.solutions-details-toogle-dropdownline-close {
    font-size: 3rem;
    color: #8d8ba7;
    rotate: 180deg;
    margin-left: -1rem;
}

.solutions-details-toggel-all {
    cursor: pointer;
}

.solutions-details-view-more-expand {
    height: auto;
    overflow: hidden;
    transition: height 0.3z ease-in-out;
}

.solutions-details-view-more-collapse {
    height: 7rem;
    overflow: hidden;
    transition: height 0.3z ease-in-out;
}

.contact-loaction {
    background: rgba(242, 235, 196, 0.4);
    padding: 176px 110px;
}

@media only screen and (min-width: 577px) and (max-width: 1024px) {
    .contact-loaction {
        padding: 176px 60px;
    }

    .solutions-details-page-title {
        color: #0d0d0d;
        font-size: 32px;
        font-family: Inter;
        font-weight: 900;
        word-wrap: break-word;
    }

    .solutions-details-page-details {
        color: #333333;
        font-size: 14px;
        font-family: Inter;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 576px) {
    .contact-loaction {
        padding: 176px 30px;
    }

    .solutions-details-page-img {
        width: 100%;
        border-radius: 15px;
        margin-bottom: 1rem;
    }
}

/* Forms */
.red-border {
    border-color: red;
}

.error-message,
.span-required {
    color: red;
}

.formlabel-title {
    font-weight: 700;
    font-size: 18px;
    color: #0d0d0d;
}

.btn-submit-form:hover {
    background-color: #fff !important;
    color: #063b67 !important;
    border-color: #063b67 !important;
}

.btn-rest-form:hover {
    background-color: #063b67 !important;
    color: #fff !important;
    border-color: #063b67 !important;
}

.contact-desc {
    font-size: 18px;
    font-weight: 400;
    color: #0d0d0d;
}

.form-control,
.form-select {
    height: 72px !important;
    border-radius: 20px !important;
    font-size: 18px !important;
    line-height: 18px !important;
}

.form-background {
    padding: 60px 40px 80px 40px;
    gap: 24px;
    border-radius: 25px;
    background: #e0e0e0;
}

.form-check-label {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding-left: 10px;
    padding-top: 7px;
}

.form-check-input {
    border: 2px solid black !important;
    border-radius: 20% !important;
    width: 24px !important;
    height: 24px !important;
    background-color: #e0e0e0 !important;
}

.form-check-input[type="radio"] {
    border-radius: 50% !important;
}

.form-check-input:checked {
    background-color: var(--cj-blue-sky) !important;
    border: 3px solid var(--cj-blue-sky) !important;
}

.toast-floater {
    z-index: 1400;
    position: fixed;
    display: flex;
    right: 55px;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    top: 35px;
}

.toast-img {
    height: 20px;
    width: 20px;
    margin-bottom: 1px;
}

.cj-toast-bg {
    background-color: #ebfde2 !important;
    border: 2px solid #52c41a !important;
    max-width: 600px !important;
    width: auto !important;
}

@media only screen and (max-width: 665px) {
    .toast-floater {
        right: 0px;
    }
    .cj-toast-bg {
        max-width: 300px !important;
    }
}

.cj-toast-bg-error {
    background-color: rgb(255, 238, 240) !important;
    border: 2px solid #ff3545 !important;
    max-width: 600px !important;
    width: auto !important;
}

.cj-toast-text {
    color: rgb(46, 82, 28);
    font-weight: 600;
}

.cj-toast-text-error {
    color: rgb(25, 42, 76);
    font-weight: 600;
}

.cj-textarea {
    resize: none;
    height: initial !important;
    overflow: hidden;
    padding-top: 20px !important;
}

button.form-submit-btn {
    border: 1px solid var(--cj-blue-sky) !important;
}

/* search card css start please dont remove below css */

.search-card {
    background: white;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
}

/* search card css end */
