.testimonialscard{
    border-radius: 2rem;
    margin-bottom: 50px;
    padding: 40px 32px;
    /* width: 100%; */
    max-width: 1200px;
    height: 85%;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
}

.testimonial-card-desc1,
.testimonial-card-desc2,
.testimonial-card-desc3,
.testimonial-card-desc4,
.testimonial-card-desc5,
.testimonial-name,
.testimonial-occupation,
.testimonial-agency,
.testimonial-company{
    text-align: start;
    font-size: 16px !important;
    line-height: 1.7 !important;
    font-style: italic;
    color: #000;
}

.testimonial-name{
    margin-bottom: 0px;
    color: rgba(0, 84, 160, 1);
    font-weight: 700px;
}

.testimonial-occupation,
.testimonial-agency,
.testimonial-company{
    margin: 0px;
}