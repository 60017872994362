.contact-form {
    border-radius: 25px;
    background: #e0e0e0;
    padding: 65px 40px;
}

.clsinput {
    height: 72px;
    border-radius: 20px;
    background: var(--Neutral-Colors-White, #fff);
}

.clsinputtextarea {
    display: flex;
    padding: 24px 24px 100px 24px;
    align-items: flex-start;
    gap: var(--sds-size-space-0);
    border-radius: 20px;
    background: var(--Neutral-Colors-White, #fff);
}

.contactdesc-title {
    color: #038c7f;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
}

.contactdesc-heading {
    color: #0d0d0d;
    font-size: 36px;
    font-weight: 700;
    line-height: 46px;
}

@media only screen and (max-width: 992px) {
    .contactlocationrow {
        flex-direction: column-reverse;
        display: flex;
        gap: 40px;
    }
}

.location {
    padding-left: 40px;
    margin-bottom: 40px;
}

@media only screen and (min-width: 576px) and (max-width: 1200px) {
    .contactdesc-heading {
        font-size: 32px;
    }
}

@media only screen and (min-width: 374px) and (max-width: 576px) {
    .contactdesc-heading {
        font-size: 28px;
    }
}
