.terms-conditions-content {
    padding: 80px 110px 150px 110px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .terms-conditions-content {
        padding: 80px 60px 150px 60px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 992px) {
    .terms-conditions-content {
        padding: 80px 30px 150px 30px;
    }
}

@media only screen and (min-width: 374px) and (max-width: 575px) {
    .terms-conditions-content {
        padding: 80px 20px 150px 20px;
    }
}


.terms-conditions-title,
.terms-conditions-title1 {
    width: 100%;
    font-weight: 700;
    color: #0D0D0D;
    font-size: 20px;
    font-family: Inter;
    line-height: 30px;
    word-wrap: break-word;
}

.terms-conditions-title1 {
    margin-top: 30px;
}

.terms-conditions-desc,
.terms-conditions-desc1 {
    width: 100%;
    font-weight: 400;
    color: #0D0D0D;
    font-size: 18px;
    font-family: Inter;
    line-height: 30px;
    word-wrap: break-word;
}