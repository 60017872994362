
.forms-links-item-container {
    width: 100%;
    text-align: left;
    justify-content: space-between;
    border: none;
    cursor: pointer;
    background: #eeeeee;
    border-bottom: solid 2px;
}

.forms-link-item-content-container {
    transition: height 0.7s ease-in-out;
    margin-top: 15px;
}

.forms-links-item-container.active {
    color: #0d0d0d;
}

.forms-link-url{
    color: #337ab7;
    text-decoration: none;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    font-family: Inter;
    line-height: 30px;
    word-wrap: break-word;
}

.form-link-item{
    font-size: 18px;
}

.forms-link-url:hover{
    color: #243773;
    cursor: pointer;
    text-decoration: underline;
}

.forms-link-item-content {
    width: 100%;
    padding-left: 1rem !important;
    color: #0d0d0d !important;
    font-size: 18px !important;
}

.formslink-header{
    padding-left: 20px;
    font-size: 24px !important;
    margin-top: 30px;
}


.forms-links-content{
    padding: 40px 2px 50px 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1445px) {
    .forms-links-content{
        padding: 40px 50px 50px 50px;
    }
}

@media only screen and (min-width: 1023px) and (max-width: 1200px) {
    .forms-links-content{
        padding: 40px 25px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 1023px) {
    .forms-links-content{
        padding: 40px 0px;
    }
}

@media only screen and (min-width: 374px) and (max-width: 575px) {
    .forms-links-content{
        padding: 40px 5px;
    }
}