.breadcrumb-content {
    padding: 25px 110px;
}

@media only screen and (min-width: 1023px) and (max-width: 1200px) {
    .breadcrumb-content {
        padding: 25px 60px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 1023px) {
    .breadcrumb-content {
        padding: 25px 30px;
    }
}

@media only screen and (min-width: 374px) and (max-width: 575px) {
    .breadcrumb-content {
        padding: 25px 20px;
    }
}

.breadcrumb {
    margin: 0px;
}

hr {
    margin: 0px;
}

.breadcrumb-item {
    color: #0d0d0d;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.08px;
}

.breadcrumb-item a {
    text-decoration: none;
    color: #243773;
}

.breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, ">");
}

.breadcrumb-item.active {
    text-decoration: none;
    color: #0d0d0d;
}
