
.executive-team{
    padding: 80px 110px 150px 110px;
} 

@media only screen  and (min-width: 992px) and (max-width: 1200px) {
    .executive-team{
        padding: 80px 60px 150px 60px;
    }
}

@media only screen  and (min-width: 576px) and (max-width: 991px) {
    .executive-team{
        padding: 80px 30px 150px 30px;
    }
}


@media only screen  and (min-width: 374px) and (max-width: 576px) {
    .executive-team{
        padding: 80px 20px 150px 20px;
    }
}

